import React from "react";

type AboutSectionProps = {};

const AboutSection: React.FC<AboutSectionProps> = () => {
  return (
    <section
      id="about"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="About me"
    >
      <div className="sticky top-0 z-20 w-screen px-6 py-5 mb-4 -mx-6 bg-slate-900/75 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold tracking-widest uppercase text-slate-200 lg:sr-only">
          About
        </h2>
      </div>
      <div className="text-slate-400">
        <p className="mb-4">
          Back in 2022, in a quest for personal and professional growth, I
          delved into the world of coding. Over the past two years, I've
          immersed myself in JavaScript and full-stack development, I've
          <span className="z-20 py-8 font-bold hover:text-teal-300 text-slate-200">
            {" "}
            turned interest into expertise
          </span>
          . Now, I'm keen to contribute to a team that values fresh ideas and
          transforms challenges into opportunities for innovation and success.
        </p>
        <p className="mb-4">
          Currently, I'm dedicated to{" "}
          <span className="z-20 py-8 font-bold hover:text-teal-300 text-slate-200">
            {" "}
            continuous learning
          </span>{" "}
          and mastering new technologies, deepening my understanding of familiar
          tools like Flask. While working on freelance projects, I am actively
          seeking opportunities to{" "}
          <span className="z-20 py-8 font-bold hover:text-teal-300 text-slate-200">
            {" "}
            join a developer team,
          </span>{" "}
          where I can contribute to more complex, structured projects.
          <br />
          My goal is to combine my growing expertise with a collaborative team
          environment to tackle challenging tasks and contribute to meaningful
          solutions.
        </p>
        <p></p>
      </div>
    </section>
  );
};
export default AboutSection;
