import "../styles/skills.css";

const LanguageIcon: React.FC<{ name: string; level: string }> = ({
  name,
  level,
}) => {
  const starCount = parseInt(level, 10);

  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <svg
          key={i}
          className="w-3 h-3 text-yellow-400"
          fill={i <= starCount ? "currentColor" : "none"}
          stroke={i <= starCount ? "none" : "currentColor"}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 .587l3.668 7.435 8.332 1.21-6.001 5.848 1.418 8.268L12 18.896l-7.417 3.902 1.418-8.268-6.001-5.848 8.332-1.21z" />
        </svg>
      );
    }
    return stars;
  };

  return (
 
    <li
      key={name}
      className="flex items-center justify-center md:justify-start"
    >
      <div className="inline-flex items-center mr-2 language-name">
        <div className="flex items-center px-3 py-1 text-xs font-medium leading-5 text-teal-300 transition-colors duration-300 rounded-full bg-teal-400/10 hover:bg-teal-300 hover:text-slate-900">
          {name}
        </div>
        <span className="hidden px-2 py-1 ml-2 text-xs text-white rounded opacity-0 language-stars bg-slate-800/50 group-hover:flex">
          {renderStars()}
        </span>
      </div>
    </li>
  );
};

export default LanguageIcon;
