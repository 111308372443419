import React, { useState } from "react";
import skillsDataJson from "../data/skills.json";
import languagesData from "../data/languages.json";
import LanguageIcon from "./LanguageIcon";
import "../styles/skills.css";

interface Skill {
  name: string;
  imageUrl: string;
}

interface SkillsData {
  [category: string]: Skill[];
}

const skillsData: SkillsData = skillsDataJson as SkillsData;

const SkillsSection: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <section
      id="skills"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="My Skills"
    >
      <div className="sticky top-0 z-20 w-screen px-6 py-5 mb-4 -mx-6 bg-slate-900/75 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold tracking-widest uppercase text-slate-200 lg:sr-only">
          Skills & Technologies
        </h2>
      </div>

      {Object.entries(skillsData).map(([category, skills]) => (
        <div
          key={category}
          className="relative grid grid-cols-1 pb-1 mb-12 transition-all group sm:grid-cols-8 "
        >
          {/* Decorative Element */}
          <div className="absolute z-0 hidden transition rounded-md -inset-x-4 -inset-y-4 motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:grayscale-0 lg:group-hover:bg-slate-800/50 "></div>

          <header className="z-10 mt-1 mb-12 text-xs font-semibold tracking-wide uppercase lg:mb-2 text-slate-500 sm:col-span-2 group-hover:text-slate-300 group-hover:font-bold">
            {category}
          </header>

          <div className="z-10 ml-4 sm:col-span-6">
            <div className="grid grid-cols-3 gap-3 gap-y-14 sm:grid-cols-4 md:grid-cols-6">
              {skills.map((skill) => (
                <div className="relative flex items-center justify-center col-span-1 skill-icon h-11">
                  <img
                    src={skill.imageUrl}
                    alt={skill.name}
                    className="transition duration-200 delay-200 w-7 h-7 grayscale group-hover:grayscale-0 group-hover:w-9 group-hover:h-9 group-hover:shadow-lg"
                  />
                  <span className="skill-name absolute px-2 py-1 mb-2 text-[10px] text-gray-300 rounded opacity-0 bg-slate-900 bottom-full">
                    {skill.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      <div className="relative grid grid-cols-1 pb-1 mb-12 sm:grid-cols-8 group ">
        <div className="absolute z-0 hidden transition rounded-md -inset-x-4 -inset-y-4 motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:grayscale-0 lg:group-hover:bg-slate-800/50 "></div>

        <header className="z-10 mt-1 mb-2 text-xs font-semibold tracking-wide uppercase text-slate-500 sm:col-span-3 group-hover:text-slate-200">
          Spoken languages
        </header>
        <ul className="z-10 flex flex-col justify-center gap-4 md:grid md:grid-cols-1">
          {languagesData.map((language) => (
            <li
              key={language.name}
              className="flex items-center justify-center md:justify-start"
            >
              <LanguageIcon name={language.name} level={language.level} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SkillsSection;
