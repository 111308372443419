import React, { useState } from "react";
import experienceData from "../data/experience.json";

type ExperienceSectionProps = {};

interface JobPositionProps {
  timeframe: string;
  position: string;
  company: string;
  description: string;
  technologies: string[];
  isDimmed: boolean;
}

const JobPosition: React.FC<JobPositionProps> = ({
  timeframe,
  position,
  company,
  description,
  technologies,
  isDimmed,
}) => {
  return (
    <div
      className={`group relative mb-12 grid grid-cols-1 sm:grid-cols-8 pb-1 transition-all ${
        isDimmed ? "opacity-50" : "hover:opacity-100"
      }`}
    >
      {/* Decorative Element */}
      <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
      <header className="z-10 mt-1 mb-2 text-xs font-semibold tracking-wide uppercase text-slate-500 sm:col-span-2">
        {timeframe}
      </header>
      <div className="z-10 sm:col-span-6">
        <h3 className="font-medium leading-snug text-slate-200 group-hover:text-teal-300">
          {position} · <span className="inline-block">{company}</span>
        </h3>
        <p className="mt-2 text-sm leading-normal">{description}</p>
        <ul className="flex flex-wrap mt-2" aria-label="Technologies used">
          {technologies.map((tech) => (
            <li key={tech} className="mr-1.5 mt-2">
              <div className="flex items-center px-3 py-1 text-xs font-medium leading-5 text-teal-300 rounded-full bg-teal-400/10">
                {tech}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ExperienceSection: React.FC<ExperienceSectionProps> = () => {
  const [hoveredJobIndex, setHoveredJobIndex] = useState<number | null>(null);

  return (
    <section
      id="experience"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="Work experience"
    >
      <div className="sticky top-0 z-20 w-screen px-6 py-5 mb-4 -mx-6 bg-slate-900/75 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold tracking-widest uppercase text-slate-200 lg:sr-only">
          Experience
        </h2>
      </div>
      <div>
        {experienceData.map((job, index) => (
          <div
            onMouseEnter={() => setHoveredJobIndex(index)}
            onMouseLeave={() => setHoveredJobIndex(null)}
          >
            <JobPosition
              key={index}
              timeframe={job.timeframe}
              position={job.position}
              company={job.company}
              description={job.description}
              technologies={job.technologies}
              isDimmed={hoveredJobIndex !== null && hoveredJobIndex !== index}
            />
          </div>
        ))}
        <div>
          <div className="mt-12">
            <a
              className="inline-flex items-center font-medium leading-tight text-slate-200 group"
              aria-label="View CV"
              href="/cv_phuoc.pdf"
            >
              <span>
                <span className="pb-px transition border-b border-transparent group-hover:border-teal-300 motion-reduce:transition-none">
                  View CV{" "}
                </span>
                <span className="whitespace-nowrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="inline-block w-4 h-4 ml-1 transition-transform -translate-y-px shrink-0 group-hover:translate-x-2 group-focus-visible:translate-x-2 motion-reduce:transition-none"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ExperienceSection;
