import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main";

function App() {
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  const cursorEffectStyle: React.CSSProperties = {
    position: "fixed",
    left: cursorPos.x,
    top: cursorPos.y,
    width: "500px",
    height: "500px",
    borderRadius: "0%",
    background:
      "radial-gradient(circle at center, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.03) 0%, transparent 90%)",
    pointerEvents: "none",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
  };

  const spotlightStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle 300px at ${cursorPos.x}px ${cursorPos.y}px, transparent, #000 70%)`,
    pointerEvents: "none",
    mixBlendMode: "lighten",
  };

  return (
    <div className="antialiased leading-relaxed bg-slate-900 text-slate-400 selection:bg-teal-300 selection:text-teal-900">
      <div
        onMouseMove={handleMouseMove}
        className="max-w-screen-xl min-h-screen px-6 py-12 mx-auto font-sans md:px-12 md:py-20 lg:px-24 lg:py-0"
      >
        {/* accessibility */}
        <a
          href="#about"
          className="absolute top-0 left-0 block px-4 py-3 text-sm font-bold tracking-widest text-white uppercase -translate-x-full rounded bg-gradient-to-br from-teal-400 via-blue-500 to-purple-600 focus-visible:translate-x-0"
        >
          Skip to Content
        </a>
        {/* <div style={spotlightStyle} /> */}
        <div style={cursorEffectStyle} />
        <div className="lg:flex lg:justify-between lg:gap-4">
          <Header />
          <Main />
        </div>
      </div>
    </div>
  );
}

export default App;
