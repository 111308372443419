import React, { useState } from "react";
import projectsData from "../data/projects.json";

interface ProjectIconProps {
  title: string;
  description: string;
  imageUrl: string;
  techStack: string[];
  siteUrl?: string;
  isDimmed: boolean;
}

const ProjectIcon: React.FC<ProjectIconProps> = ({
  title,
  description,
  imageUrl,
  techStack,
  siteUrl,
  isDimmed,
}) => {
  return (
    <div
      className={`group relative mb-12 grid grid-cols-1 sm:grid-cols-8 pb-1 transition-all ${
        isDimmed ? "opacity-50" : "hover:opacity-100"
      }`}
    >
      <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>

      <img
        src={imageUrl}
        alt={title}
        className="z-10 mb-4 mr-2 transition border-2 rounded border-slate-200/10 group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1"
      />

      <div className="z-10 ml-2 sm:col-span-6 sm:order-2">
        {/* <h3 className="font-medium leading-snug text-slate-200 group-hover:text-teal-300">
            {title}
          </h3> */}
        <h3>
          <a
            className="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300 group"
            href={siteUrl}
            target="_blank"
            rel="noreferrer"
            aria-label={title}
          >
            <span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
            <span>
              {title}{" "}
              <span className="inline-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="inline-block w-4 h-4 ml-1 transition-transform translate-y-px shrink-0 group-hover:-translate-y-1 group-hover:translate-x-1 group-focus-visible:-translate-y-1 group-focus-visible:translate-x-1 motion-reduce:transition-none"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </span>
          </a>
        </h3>

        <p className="mt-2 text-sm leading-normal">{description}</p>
        <ul className="flex flex-wrap mt-2" aria-label="Technologies used">
          {techStack.map((tech, idx) => (
            <li key={idx} className="mr-1.5 mt-2">
              <div className="flex items-center px-3 py-1 text-xs font-medium leading-5 text-teal-300 rounded-full bg-teal-400/10">
                {tech}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ExperienceSection: React.FC = () => {
  const [hoveredProjectIndex, setHoveredProjectIndex] = useState<number | null>(
    null
  );

  return (
    <section
      id="projects"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="Projects"
    >
      <div className="sticky top-0 z-20 w-screen px-6 py-5 mb-4 -mx-6 bg-slate-900/75 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold tracking-widest uppercase text-slate-200 lg:sr-only">
          Projects
        </h2>
      </div>
      <div>
        {projectsData.map((project, index) => (
          <div
            onMouseEnter={() => setHoveredProjectIndex(index)}
            onMouseLeave={() => setHoveredProjectIndex(null)}
            key={index}
          >
            <ProjectIcon
              title={project.title}
              description={project.description}
              imageUrl={project.imageUrl}
              techStack={project.techStack}
              siteUrl={project.siteUrl}
              isDimmed={
                hoveredProjectIndex !== null && hoveredProjectIndex !== index
              }
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ExperienceSection;
