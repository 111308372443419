import React from "react";
import AboutSection from "./AboutSection";
import ExperienceSection from "./ExperienceSection";
import ProjectsSection from "./ProjectsSection";
import WritingSection from "./WritingSection";
import Footer from "./Footer";
import SkillsSection from "./SkillsSection";

type MainProps = {};

const Main: React.FC<MainProps> = () => {
  return (
    <main id="content" className="pt-24 lg:w-1/2 lg:py-24">
      <AboutSection />
      <ExperienceSection />
      <SkillsSection />
      <ProjectsSection />
      {/* <WritingSection />
      <Footer /> */}
    </main>
  );
};
export default Main;
