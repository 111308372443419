import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

type HeaderProps = {};
interface NavLinkProps {
  href: string;
  text: string;
  active: boolean;
}

const NavLink: React.FC<NavLinkProps> = ({ href, text, active }) => {
  const linkClasses = active
    ? " font-bold text-teal-300"
    : " text-slate-500 hover:text-slate-200 ";

  const indicatorClasses = active
    ? "w-16 bg-slate-200"
    : "w-8 bg-slate-600 group-hover:w-16 group-hover:bg-slate-200";

  return (
    <a className="flex items-center py-3 group" href={href}>
      <span
        className={`h-px mr-4 transition-all nav-indicator ${indicatorClasses} group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none`}
      ></span>
      <span
        className={`text-xs font-bold tracking-widest uppercase nav-text group-hover:text-slate-200 group-focus-visible:text-slate-200 `}
      >
        {text}
      </span>
    </a>
  );
};

const Header: React.FC<HeaderProps> = () => {
  const [currentSection, setCurrentSection] = useState("");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    // Observing each section
    document.querySelectorAll("section").forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <header className="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
      <div>
        <h1 className="text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl">
          <a href="/">Phuoc Hung</a>
        </h1>
        <h2 className="mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl">
          Web Developer
        </h2>
        <p className="max-w-xs mt-4 leading-normal">
          {/* With two years of dedicated study in software development, I am eager
          to apply my passion and growing expertise in backend technologies like
          Node.js and Flask, as well as in frontend development using
          JavaScript, React, Tailwind, and TypeScript. I am committed to
          delivering high-quality software solutions and continuously expanding
          my technical skills. */}
          {/* Driven software development enthusiast, committed to delivering
          excellence and embracing new challenges in the tech world. */}
          I am enthusiastic about contributing to and growing within innovative
          tech teams.
          <br />
          <br />
          Currently working on my Spring Boot web app
        </p>
        <nav className="hidden nav lg:block" aria-label="In-page jump links">
          <ul className="mt-16 w-max">
            <li>
              <NavLink
                href="#about"
                text="About"
                active={currentSection === "about"}
              />
            </li>
            <li>
              <NavLink
                href="#experience"
                text="Experience"
                active={currentSection === "experience"}
              />
            </li>
            <li>
              <NavLink
                href="#skills"
                text="Skills"
                active={currentSection === "skills"}
              />
            </li>
            <li>
              <NavLink
                href="#projects"
                text="Projects"
                active={currentSection === "projects"}
              />
            </li>
          </ul>
        </nav>
      </div>
      <ul className="flex items-center mx-4 mt-8" aria-label="Social media">
        <li className="p-4 mr-5 text-xs">
          <a
            className="block hover:text-slate-200 hover:text-base"
            href="https://github.com/puokl"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} className="w-6 h-6" />
          </a>
        </li>
        <li className="ml-5 text-xs">
          <a
            className="block hover:text-slate-200 hover:text-base"
            href="https://www.linkedin.com/in/phuoc-l-56b894116/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6" />
          </a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
